import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Image, Icon } from "@atoms";
import { m, AnimatePresence, useTransform, useScroll } from "framer-motion";
import { useAppState } from "@state";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import ImageModalContainer from "./ImageModalContainer";

const ModelCarousel = ({ approaches }) => {
  const ref = useRef();
  const [unfold, setUnfold] = useState(false);
  const [active, setActive] = useState(0);
  // lang
  const lang = useLang();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const y = {
    0: useTransform(scrollYProgress, [0.25, 0.4], ["-100%", "0%"]),
    1: useTransform(scrollYProgress, [0.35, 0.5], ["-100%", "0%"]),
    2: useTransform(scrollYProgress, [0.45, 0.6], ["-100%", "0%"]),
    3: useTransform(scrollYProgress, [0.55, 0.7], ["-100%", "0%"]),
  };

  const opacity = {
    0: useTransform(scrollYProgress, [0.25, 0.35], [0, 1]),
    1: useTransform(scrollYProgress, [0.35, 0.45], [0, 1]),
    2: useTransform(scrollYProgress, [0.45, 0.55], [0, 1]),
    3: useTransform(scrollYProgress, [0.55, 0.65], [0, 1]),
  };

  const [, dispatch] = useAppState();

  const setModalContent = idx => {
    dispatch({
      type: "openModal",
      allowScroll: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: () => (
        <ImageModalContainer modalImages={approaches} initial={idx} />
      ),
    });
  };

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setUnfold(v > 0.725);
    });
    return () => {
      unsubscribe();
    };
  }, [ref.current]);

  return (
    <div className="flex items-center justify-center" ref={ref}>
      <div className="mb-[175vh]" />

      <div className="sticky top-1/3 mx-auto flex w-full max-w-3xl items-center justify-center px-1.5 sm:top-1/4 md:px-0">
        {/* prev button */}
        <AnimatePresence>
          {unfold && (
            <m.button
              type="button"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={() => setActive(last => (last > 0 ? last - 1 : last))}
              className="group absolute inset-y-0 left-0 top-[155%] z-20 my-auto hidden h-16 w-16 shrink-0 items-center justify-center rounded-full border-2 border-white p-3 transition-transform duration-300 hover:scale-105 sm:-left-4 sm:top-8 sm:flex md:left-8"
            >
              <Icon
                name="arrow"
                className="h-4 w-4 rotate-180 text-white transition duration-300 group-hover:scale-110"
                fitHeight
              />
            </m.button>
          )}
        </AnimatePresence>

        {/* carousel body */}
        <m.div className="flex w-full items-center justify-center transition-transform duration-500 ease-in-out">
          {approaches.map((approach, i) => (
            <m.div
              className={classNames("relative aspect-portal", {
                "mx-2": !unfold,
                "-mx-3 sm:-mx-5": unfold,
                "z-0 w-[8.5rem] sm:w-[10rem] md:w-[12rem]":
                  !unfold || (unfold && active !== i),
                "z-10 w-[10rem] sm:w-[12rem] md:w-[14rem]":
                  unfold && active === i,
              })}
              style={{ opacity: opacity[i] }}
              whileHover={unfold ? { scale: 1.05 } : {}}
              layout
            >
              <m.div
                style={{ y: y[i] }}
                className={classNames(
                  "relative h-full w-full overflow-hidden rounded-t-full bg-gradient-to-b from-40% to-transparent transition-[background] transition-[border] transition-[opacity]",
                  {
                    "border-2 border-white from-transparent": !unfold,
                    "border-0 border-transparent": unfold,
                    "from-offwhite": unfold && i === 0,
                    "from-light-blue": unfold && i === 1,
                    "from-light-green": unfold && i === 2,
                    "from-coral": unfold && i === 3,
                    "opacity-40": unfold && active !== i,
                  }
                )}
              >
                <button
                  type="button"
                  onClick={() => {
                    if (active === i && unfold) {
                      setModalContent(i);
                    } else {
                      setActive(i);
                    }
                  }}
                  className={classNames("absolute inset-0 z-10 h-full w-full", {
                    "pointer-events-none": !unfold,
                  })}
                  aria-label={t("Expand Story", lang)}
                >
                  <div
                    className={classNames(
                      "absolute inset-0 h-full w-full transition-opacity",
                      {
                        "opacity-0": !unfold || (unfold && active !== i),
                      }
                    )}
                  >
                    <Image
                      image={approach?.backgroundImage}
                      fill
                      sourceArray={[1100, 3600]}
                    />
                  </div>
                </button>
              </m.div>
              <button
                type="button"
                onClick={() => {
                  if (active !== i) {
                    setActive(i);
                  } else {
                    setModalContent(i);
                  }
                }}
                className={classNames(
                  "absolute inset-x-0 top-full mx-auto mt-5 inline-block text-left text-center text-xs font-bold uppercase leading-loose tracking-wider text-white underline decoration-2 underline-offset-4",
                  { hidden: !unfold || (unfold && active !== i) }
                )}
              >
                {approach.title}
              </button>
            </m.div>
          ))}
        </m.div>

        {/* next button */}
        <AnimatePresence>
          {unfold && (
            <m.button
              type="button"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={() =>
                setActive(last =>
                  last < approaches.length - 1 ? last + 1 : last
                )
              }
              className="group absolute inset-y-0 right-0 top-[155%] z-20 my-auto hidden h-16 w-16 shrink-0 items-center justify-center rounded-full border-2 border-white p-3 transition-transform duration-300 hover:scale-105 sm:-right-4 sm:top-8 sm:flex md:right-8"
            >
              <Icon
                name="arrow"
                className="h-4 w-4 text-white transition duration-300 group-hover:scale-110"
                fitHeight
              />
            </m.button>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ModelCarousel;
